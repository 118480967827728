import React, { SFC, memo } from "react"
import styled from 'styled-components'
import SmokeElement from "smoke-effect-react"
import SVG from 'react-inlinesvg'

import Layout from "../components/layout"
import SEO from "../components/seo"

import LogoText from '../images/logo-text.svg'
import ComingSoon from '../images/coming-soon.svg'
import SmokeImage from '../images/smoke.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90vw;
  height: 60vh;
  background: transparent;
  position: absolute;
  top: 20vh;
  left: 5vw;
`

const TextImage = styled(SVG)`
  margin: 0;
  width: 50vw;
  height: 80px;
  filter: drop-shadow(2px 4px 6px green);

  @media(max-width: 500px) {
    width: 70vw;
  }
`

const ComingSoonImage = styled(TextImage)`
  width: 30vw;
  height: 50px;
  margin-top: 100px;

  @media(max-width: 500px) {
    width: 40vw;
    margin-top: 50px;
  }
`

const IndexPage: SFC = () => (
  <Layout>
    <SEO title="Home" />
    <Wrapper>
      <TextImage src={LogoText} />
      <ComingSoonImage src={ComingSoon}/>
    </Wrapper>
    <SmokeElement 
      src=""
      opacity="1"
      smokeSrc={SmokeImage}
      smokeOpacity="0.9" />
  </Layout>
)

export default memo(IndexPage)